<template>
    <v-dialog hide-overlay persistent :value="show" max-width="450px">
        <v-card color="secondary">
            <v-card-text class="white--text pt-4">
                {{ message }} - this might take a few minutes, please remain on this page.
                <v-progress-linear indeterminate color="white" class="my-4"></v-progress-linear>
                <div>
                    In the meantime you can take a look at our
                    <a href="https://docs.nuvolos.cloud/" target="_blank" class="white--text">getting started guide</a>.
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        message: String,
        show: Boolean
    },
    methods: {
        warnUnload(event) {
            // Cancel the event as stated by the standard.
            event.preventDefault()
            // Older browsers supported custom message
            event.returnValue = ''
        }
    },
    watch: {
        show: {
            handler: function(to, from) {
                if (to) {
                    window.addEventListener('beforeunload', this.warnUnload)
                } else {
                    window.removeEventListener('beforeunload', this.warnUnload)
                }
            },
            immediate: true
        }
    }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        persistent: "",
        value: _vm.show,
        "max-width": "450px"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "secondary" } },
        [
          _c(
            "v-card-text",
            { staticClass: "white--text pt-4" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.message) +
                  " - this might take a few minutes, please remain on this page. "
              ),
              _c("v-progress-linear", {
                staticClass: "my-4",
                attrs: { indeterminate: "", color: "white" }
              }),
              _c("div", [
                _vm._v(" In the meantime you can take a look at our "),
                _c(
                  "a",
                  {
                    staticClass: "white--text",
                    attrs: {
                      href: "https://docs.nuvolos.cloud/",
                      target: "_blank"
                    }
                  },
                  [_vm._v("getting started guide")]
                ),
                _vm._v(". ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }